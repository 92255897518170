(function()
{
	'use strict';

	angular.module('crossyn', [ 'atsc', 'forms', 'ngMaterial' ])
	.config([ '$mdAriaProvider', function($mdAriaProvider)
	{
		// Globally disables all ARIA warnings.
		$mdAriaProvider.disableWarnings();
	}])
	.config(['$mdThemingProvider', function($mdThemingProvider)
	{
		$mdThemingProvider.definePalette('crossynPrimary',
		{
			'50': '#e5e4ec',
			'100': '#bebcd0',
			'200': '#9390b0',
			'300': '#676490',
			'400': '#474279',
			'500': '#262161',
			'600': '#221d59',
			'700': '#1c184f',
			'800': '#171445',
			'900': '#0d0b33',
			'A100': '#736eff',
			'A200': '#423bff',
			'A400': '#1008ff',
			'A700': '#0700ee',
			'contrastDefaultColor': 'light',
			'contrastDarkColors': ['50', '100', '200', 'A100'],
			'contrastLightColors': ['300', '400', '500', '600', '700', '800', '900', 'A200', 'A400', 'A700']
		});

		$mdThemingProvider.definePalette('crossynAccent',
		{
			'50': '#000000',
			'100': '#030102',
			'200': '#150814',
			'300': '#280e25',
			'400': '#3b1537',
			'500': '#4e1b48',
			'600': '#74296b',
			'700': '#872f7d',
			'800': '#9a368f',
			'900': '#ad3ca0',
			'A100': '#74296b',
			'A200': '#61225A',
			'A400': '#4e1b48',
			'A700': '#be45b0',
			'contrastDefaultColor': 'light',
			'contrastDarkColors': ['50', '100', '200', '300', '400', 'A100'],
			'contrastLightColors': undefined
		});

		$mdThemingProvider.theme('default').primaryPalette('crossynPrimary').accentPalette('crossynAccent');
	}])
})();

var reload, lockScreen, unlockScreen, sessionID;

(function()
{
	'use strict';
	angular.module('crossyn').controller('WrapperController', ['$scope', '$mdDialog', '$http', '$element', '$compile', '$q', '$rootScope', '$mdMenu', '$timeout', function($scope, $mdDialog, $http, $element, $compile, $q, $rootScope, $mdMenu, $timeout)
	{
		var controller											= this;
		var $pages												= $element.find('.main');
		var initialTitle										= window.document.title;
		var ignoreHashChange									= false;
		var initialized											= false;
		var screenLocks											= [];
		var homeURL												= '/dashboard';

		lockScreen = function(pageScope)
		{
			var index											= $.inArray(pageScope, screenLocks);

			if (index == -1)
			{
				screenLocks.push(pageScope);
			}
		};

		unlockScreen = function(pageScope)
		{
			var index											= $.inArray(pageScope, screenLocks);

			if (index > - 1)									{ screenLocks.splice(index, 1); }
		};

		var isScreenLocked = function()
		{
			return screenLocks.length > 0;
		}

		$(window).on('beforeunload', function(e)
		{
			if (isScreenLocked())
			{
				e.preventDefault();

				return 'Wijzigingen die je hebt aangebracht, worden mogelijk niet opgeslagen.';
			}
		});

		controller.pages										= 0;
		controller.currentURL									= '';
		controller.currentProjectID								= 0;
		controller.history										= [];
		controller.pageScopes									= [];

		var saveHistory = function()
		{
			try													{ window.sessionStorage.pageHistory = JSON.stringify(controller.history); }
			catch (ex)											{ /* no problem */ }

			controller.pages									= controller.history.length + 1;
		};

		controller.back = function()
		{
			window.history.back();
		};

		$rootScope.goBack = function()
		{
			controller.back();
		};

		$rootScope.setHash = function(hash)
		{
			window.location.hash = hash;
		};

		controller.setHash = function(hash)
		{
			window.location.hash = hash;
		};

		controller.clearVehicleMonitorStorage = function()
        {
		    localStorage.removeItem('vehicleMonitorPageNumber')
            localStorage.removeItem('vehicleMonitorFilter')
        };

		controller.setLanguage = function(language)
		{
			window.location.href = '/?lang=' + language + window.location.hash;
		};

		$scope.$on('table.onError', function(e, settings, techNote, message)
		{
			$http({
				url: '/',
				method: 'get'
			}).then(function(response)
			{
				if (response.status == 200 && response.data.indexOf('ng-controller="LoginController') == -1)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.title('Error')
							.textContent(message)
							.ok('OK')
					);
				}
				else
				{
					reload();
				}
			}, reload);
		});

		var render = function(html)
		{
			if (html.indexOf('ng-controller="LoginController') > -1)
			{
				// Login page. Reload the current page.
				screenLocks.splice(0, screenLocks.length);
				window.location.reload(false);
			}
			else
			{
				var elements									= jQuery.parseHTML(html, null, true);

				var $page										= $pages.find('.page[data-id=' + controller.pages + ']');
				var $content									= $page.find('> .content');

				$content.empty();

				if (controller.pageScopes[controller.pages - 1])
				{
					controller.pageScopes[controller.pages - 1].$destroy();
				}

				var scope										= $scope.$new();
				controller.pageScopes[controller.pages - 1]		= scope;

				scope.$pageScope								= true;

				scope.title										= '';

				for (var i = 0; i < elements.length; i = i + 1)
				{
					if		(elements[i].tagName == 'TITLE')	{ controller.pageScopes[controller.pages - 1].title = elements[i].innerText; }
					else if (elements[i].tagName == 'META')		{ /* no problem */ }
					else
					{
						try										{ $content.append($compile(elements[i])(scope)); }
						catch (ex)								{ console.error(ex); }
					}
				}

				window.document.title							= scope.title ? (scope.title + ' - ' + initialTitle) : initialTitle;

				$page.attr('data-loaded', 'true');
			}

			$scope.$broadcast('initData', controller);
		};

		var waitForHashChange = function(resolve)
		{
			if (ignoreHashChange)								{ setTimeout(waitForHashChange.bind(this, resolve), 10); }
			else												{ resolve(); }
		};

		var moveHistory = function(i)
		{
			ignoreHashChange									= true;

			window.history.go(i);

			return $q(waitForHashChange);
		};

		var resetHistory = function()
		{
			// Full reset.
			var loop = function(i)
			{
				if (false && (i > 0 || window.location.hash))
				{
					if (i >= 0)									{ moveHistory(-1).then(function(){ loop(i - 1); }); }
					else										{ window.history.back(); }
				}
				else
				{
					window.history.replaceState({ 'page': 1 }, '', '#' + homeURL);
					onHashChange();
				}
			}

			loop(controller.history.length - 1);
		};

		var onHashChange = function()
		{
			if		(ignoreHashChange)							{ ignoreHashChange = false; }
			else
			{
				var hashVal										= returnHash(window.location.hash);
				var url											= $.trim(('' + hashVal).right('#'));
				var state										= window.history.state;

				if (state == null)								{ state = { 'page': controller.pages }; }
				if (state.page > controller.pages)				{ state.page = controller.pages; }

				if (isScreenLocked())
				{
					ignoreHashChange							= true;

					var index = state.page >= controller.pages ? -1 : 1;

					moveHistory(index).then(function()
					{
						$mdDialog.show(
							$mdDialog.confirm()
								.title('Wil je deze pagina verlaten?')
								.textContent('Wijzigingen die je hebt aangebracht, worden mogelijk niet opgeslagen.')
								.ok('Verlaten')
								.cancel('Blijven')
						).then(function()
						{
							screenLocks.splice(0, screenLocks.length);

							if (state.page >= controller.pages)
							{
								window.history.forward();
							}
							else
							{
								window.history.back();
							}
						});
					});
				}
				else
				{
					$(window).scrollTop(0);

					var state									= window.history.state;

					if (state == null)							{ state = { 'page': controller.pages }; }
					if (state.page > controller.pages)			{ state.page = controller.pages; }

					if (url && url != '' && url != '/' && !url.startsWith('/?') && !url.startsWith('/#'))
					{
						var loadPage = function()
						{
							return $q(function(resolve)
							{
								$http({
									'url': url,
									'type': 'get'
								}).then(function(response)
								{
									render(response.data);
									resolve();
								}, function(response)
								{
									render(response.data);
									resolve();
								});
							});
						};

						if (state.page == controller.pages)
						{
							if		(url == '/logout')
							{
								window.location.replace('/logout');
							}
							else
							{
								if (controller.currentURL && url != controller.currentURL)
								{
									var count = (url.match(/\//g) || []).length;

									if (count == 1)
									{
										var start				= 2;
										var end					= controller.pageScopes.length;

										var removedPages		= controller.pageScopes.splice(start, end);

										for (var i = 0; i < removedPages.length; i = i + 1)
										{
											if (removedPages[i])
											{
												unlockScreen(removedPages[i]);

												removedPages[i].$destroy();
											}
										}

										controller.history		= [];
										controller.pageScopes	= [];
									}

									controller.history.push(controller.currentURL);
									controller.pageScopes.push($scope.$new());
									saveHistory();
								}

								controller.currentURL			= url;
								window.history.replaceState({ 'page': controller.pages }, '', '#' + url);

								loadPage();
							}
						}
						else
						{
							var start							= state.page - 1;
							var end								= controller.history.length - state.page + 1;

							controller.history.splice(start, end + 1);

							saveHistory();

							var previousURL						= controller.currentURL;

							controller.currentURL				= url;
							window.history.replaceState({ 'page': controller.pages }, '', '#' + url);

							$timeout(function() // Prevent $rootScope:inprog errors
							{
								$scope.$apply(function()
								{
									var removedPages			= controller.pageScopes.splice(start + 1, end);

									for (var i = 0; i < removedPages.length; i = i + 1)
									{
										if (removedPages[i])
										{
											unlockScreen(removedPages[i]);

											removedPages[i].$destroy();
										}
									}

									var $page						= $pages.find('.page[data-id=' + controller.pages + ']');

									var announce = function()
									{
										if (previousURL && previousURL.endsWith('/edit'))
										{
											reload();
										}

										controller.pageScopes[controller.pages - 1].$broadcast('back', previousURL);
									};

									if ($page.attr('data-loaded') != 'true')
									{
										loadPage().then(announce);
									}
									else
									{
										window.document.title		= controller.pageScopes[controller.pages - 1].title + ' - ' + initialTitle;

										announce();
									}
								});
							}, 0);
						}
					}
					else
					{
						resetHistory();
					}
				}
			}
		};

		reload = function(tableController)
		{
			if (initialized)									{ onHashChange(); }
		};

		$(window).on('load', function()
		{
			try
			{
				if (window.sessionStorage.pageHistory)			{ controller.history = JSON.parse(window.sessionStorage.pageHistory); }
			}
			catch (ex)											{ /* no problem */ }

			for (var i = 0; i < controller.history.length + 1; i = i + 1)
			{
				controller.pageScopes.push($scope.$new());
			}

			saveHistory();

			onHashChange();

			$(window).on('hashchange', onHashChange);

			initialized											= true;
		});

		controller.execute = function(uri)
		{
			if (uri.startsWith('/'))
			{
				controller.setHash('#' + uri);
			}
		};

		// Sidebar

		var $sidebar											= $element.find('.sidebar');
		var userExpanded;

		try
		{
			if (window.localStorage['sidebarUserExpanded'])		{ userExpanded = window.localStorage['sidebarUserExpanded'] == 'true'; }
			else												{ throw ''; }
		}
		catch (ex)												{ userExpanded = $(window).width() > 1279; }

		var setClass = function()
		{
			if ($element.hasClass('sidebar-expanded'))
			{
				$sidebar.addClass('expanded');
			}
			else
			{
				$sidebar.removeClass('expanded');
			}
		}

		var expand = function()
		{
			$element.addClass('sidebar-expanded');

			setTimeout(setClass, 200);
		};

		var retract = function()
		{
			$element.removeClass('sidebar-expanded');

			setTimeout(setClass, 200);
		};

		var returnHash = function(hash)
		{
			if( hash.right('#').startsWith('/'))				{ return hash;}
			else												{ return '/';}
		}

		if (userExpanded)										{ expand(); }

		$(document).on('mousedown', function(e)
		{
			if (!userExpanded && !$(e.target).is('.sidebar, .sidebar *'))
			{
				retract();
			}
		});

		$sidebar.find('li').on('mouseenter', function()
		{
			expand();
		});

		$sidebar.on('mouseleave', function()
		{
			if (!userExpanded)									{ retract(); }
		});

		$element.find('.headbar .fa-bars').css('cursor', 'pointer').on('click', function()
		{
			userExpanded										= !userExpanded;

			try													{ window.localStorage['sidebarUserExpanded'] = userExpanded ? 'true' : 'false'; }
			catch (ex)											{ /* no problem */ }

			if (userExpanded)
			{
				expand();
			}
			else
			{
				retract();
			}
		});

		// Content box

		$(document).on('mousedown', function(e)
		{
			if (!$(e.target).is('.content-box, .content-box *'))
			{
				$element.find('.content-box').hide();
			}
		});

		controller.openContentBox = function(e)
		{
			var elem											= $(e.target);
			var alignRight										= elem.parents('.align-right').length > 0;

			if (!elem.is('md-button, .md-button'))				{ elem = elem.parents('md-button, .md-button').first(); }

			var contentElement									= elem.next();
			var offsetLeft										= parseInt(elem.offset().left.toFixed(0));

			if (alignRight)
			{
				var offsetRight									= $(window).width() - offsetLeft - elem.outerWidth();

				contentElement.css('right', offsetRight + 'px');
			}
			else
			{
				contentElement.css('left', offsetLeft + 'px');
			}

			contentElement.show();
		};

		$('.submenu').on('click', function(e)
		{
			if ($(this).hasClass('active'))
			{
				$(this).removeClass('active');
				$(this).next('ul').slideUp(350);
			}
			else
			{
				$('.sidebar .submenu.active').removeClass('active');
				$('.sidebar nav ul ul').hide();
				$(this).addClass('active');
				$(this).next('ul').slideDown(350);
			}
		});

		$sidebar.find('nav > ul > li > a').on('click', function()
		{
			$('.submenu.active').trigger('click');
		});
	}]);
})();

(function()
{
	'use strict';
	angular.module('crossyn').controller('EntityFormController', ['$rootScope', '$scope', '$mdDialog', '$http', '$controller', '$attrs', '$element', function($rootScope, $scope, $mdDialog, $http, $controller, $attrs, $element)
	{
		var controller										= $controller('FormController', {$scope: $scope, $attrs: $attrs, $element: $element});
		
		angular.extend(this, controller);
		
		controller.formData.id								= $element.find('.form-widget').attr('data-id');
	}]);
})();

(function()
{
	'use strict';
	angular.module('crossyn').controller('LoginController', ['$scope', '$mdDialog', '$http', '$element', '$window', function($scope, $mdDialog, $http, $element, $window)
	{
		var controller										= this;
		var language										= $element.attr('data-language');

		$scope.language										= language;

		$scope.$watch('language', function(newValue)
		{
			if (newValue && newValue != language)			{ window.location.replace(window.location.href.left('?') + '?lang=' + newValue); }
		});

		controller.formData									= {};

		controller.state									= 'login';

		controller.gotoTab = function(index)
		{
			$scope.selectedIndex = index;
		}

		controller.doLogin = function()
		{
			if (controller.formData.email && controller.formData.password)
			{
				try											{ window.sessionStorage.clear(); }
				catch (ex)									{ /* no problem */ }

				$http({
					url: '/login',
					method: 'post',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					data: $.param(controller.formData)
				}).then(function(response)
				{
					if (response.data.indexOf('LoginController') > -1)
					{
						$mdDialog.show(
							$mdDialog.alert()
								.clickOutsideToClose(true)
								.title(translate('login/errortitle'))
								.textContent(translate('login/error'))
								.ok('OK')
						);
					}
					else
					{
						window.location.href = '/' + (window.location.hash ? window.location.hash : '');
					}
				}, function(e)
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('login/errortitle'))
							.textContent(translate('login/error'))
							.ok('OK')
					);
				});
			}
			else
			{
				$mdDialog.show(
					$mdDialog.alert()
						.clickOutsideToClose(true)
						.title(translate('keyword/login'))
						.textContent(translate('login/error'))
						.ok('OK')
				);
			}
		}

		controller.forgotPassword = function()
		{
			controller.state							= 'reset_password_request';
		};

		// ReCAPTCHA
		$scope.recaptchaCallback = function(token)
		{
			if (token)
			{
				$http({
					url: '/login/recaptcha',
					method: 'post',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					data: 'g-recaptcha-response=' + token
				}).then(function(response)
					{
						if (response.data.result)
						{
							$scope.solvedRecaptcha				= true;
							resetPasswordRequest();
						}
						else
						{
							$mdDialog.show(
									$mdDialog.alert()
									.clickOutsideToClose(true)
									.title('Validatie mislukt')
									.htmlContent("<p>" + translate('recaptcha-datavalid/text') + "</p>")
									.ok('OK')
							);
						}
				});
			}
		};

		$window.recaptchaCallback								= $scope.recaptchaCallback;

		controller.resetPasswordRequest = function()
		{
			if (controller.formData['reset_password_request'].email)
			{
				if ($scope.solvedRecaptcha)						{ resetPasswordRequest(); }
				else											{ grecaptcha.execute(); }
			}
			else
			{
				$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('keyword/error'))
							.textContent(translate('email/error'))
							.ok('OK')
				);
			}
		};

		var resetPasswordRequest = function()
		{
			controller.message						= '';
			controller.emailExist					= '';

			$http({
				url: '/login/reset-password',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				method: 'post',
				data: $.param({email: controller.formData['reset_password_request'].email})
			}).then(function(response)
			{
				controller.state					= '';

				if (response.data.error && response.data.error.message)
				{
					controller.message				= response.data.error.message;
				}
				else
				{
					controller.message				= response.data.message;
				}
			});
		}

		controller.prev = function()
		{
			controller.state							= 'login';
		};
	}]);
})();

(function()
{
	'use strict';
	angular.module('crossyn').controller('ResetPasswordController', ['$scope', '$mdDialog', '$http', '$element', '$controller', '$attrs', function($scope, $mdDialog, $http, $element, $controller, $attrs)
	{
		var controller											= $controller('FormController', {$scope: $scope, $attrs: $attrs, $element: $element});

		angular.extend(this, controller);

		var formController										= controller.name;

		var verificationCodeResendAfterSubmit					= null;

		controller.afterSubmit = function(response, hasError)
		{
			if (!hasError)
			{
				if (response.data.newURL)						{ $scope.state = response.data.newURL; }

				if (verificationCodeResendAfterSubmit)
				{
					verificationCodeResendAfterSubmit();

					verificationCodeResendAfterSubmit			= null;
				}
			}
		};

		$scope.$on('form.specificInit', function()
		{
			$scope.state										= $scope.error == true ? 'error' : 'user_details';
		});

		$scope.prev = function()
		{
			$scope.state										= 'user_details';
		};

		$scope.verificationEmailCodeResend = function()
		{
			if ($scope.state == 'verification_email')
			{
				verificationCodeResendAfterSubmit = function()
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('keyword/resend'))
							.htmlContent(translate('emailverification/code/resend', controller.formData.email))
							.ok('OK')
					);
				};
			}

			controller.submit('user_details');
		};
	}]);
})();

(function()
{
	'use strict';

	angular.module('crossyn')
	.directive('passwordstrengthmeter', [ '$mdDialog', '$parse', function($mdDialog, $parse)
	{
		return {
			restrict: 'C',
			scope:
			{
				value: '='
			},
			link: function(scope, elem, attrs)
			{
				elem.append('<div></div>');
				elem.append('<span></span>');

				scope.$watch('value', function(newValue)
				{
					elem.removeClass('weak');
					elem.removeClass('average');
					elem.removeClass('reasonable');
					elem.removeClass('strong');

					var text									= '';

					if (newValue && newValue.password)
					{
						if		(newValue.score <= 1)
						{
							text								= translate('passwordstrength/weak');
							elem.addClass('weak');
						}
						else if (newValue.score == 2)
						{
							text								= translate('passwordstrength/average');
							elem.addClass('average');
						}
						else if (newValue.score == 3)
						{
							text								= translate('passwordstrength/reasonable');
							elem.addClass('reasonable');
						}
						else
						{
							text								= translate('passwordstrength/strong');
							elem.addClass('strong');
						}
					}

					elem.find('span').text(text);
				})
			}
		};
	}])
})();

